.card-text{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
    word-break: break-all;
}

.img-face {
    display: block;
    width: 40px; /* 图片宽度 */
    height: 40px; /* 图片高度 */
    border-radius: 50%; /* 圆角半径 */
    object-fit: cover; /* 图片按比例缩放，并铺满容器 */
}

.img-face-small {
    display: block;
    margin: 1px;
    width: 22px; /* 图片宽度 */
    height: 22px; /* 图片高度 */
    border-radius: 50%; /* 圆角半径 */
    object-fit: cover; /* 图片按比例缩放，并铺满容器 */
}

.img-share-card {
    height: 100px;
    width: 177px;
}


/* 卡片容器的样式 */
.card {
    width: calc(600px - 44px); /* 卡片宽度 */
    border-radius: 10px; /* 圆角半径 */
    overflow: hidden; /* 隐藏溢出部分 */
    background: white;
    margin: 20px;
    padding: 24px;
}
.card-share {
    width: calc(500px - 44px); /* 卡片宽度 */
    height: 100px;
    margin-top: 4px;
    margin-bottom: 8px;

    border-radius: 10px; /* 圆角半径 */
    overflow: hidden; /* 隐藏溢出部分 */
    background: #f2f3f5;
}
.card-sub {
    width: calc(530px - 44px); /* 卡片宽度 */
    border-radius: 10px; /* 圆角半径 */
    overflow: hidden; /* 隐藏溢出部分 */
    background: #f2f3f5;
    margin-left: 33px;
    margin-top: 10px;
}
.card-sub-share {
    width: calc(480px - 44px); /* 卡片宽度 */
    height: 100px;
    margin-top: 4px;
    margin-bottom: 8px;

    border-radius: 10px; /* 圆角半径 */
    overflow: hidden; /* 隐藏溢出部分 */
    background: white;
}

/* 卡片容器的样式 */
.card-content {

}

.span-item {
    display: block; /* 设置为块级元素 */
    float: left; /* 左浮动 */
}

.ellipsis {
    white-space: nowrap; /* 不允许换行 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 超出部分使用省略号代替 */
    width: 100%;
}
